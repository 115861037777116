
document.addEventListener('DOMContentLoaded', function(){
    var edit_comment = document.getElementsByClassName("edit_comment"); 
    var comment_back = document.getElementsByClassName("comment_back");
    var comment_update = document.getElementsByClassName("comment_update");
    var input__title = document.getElementsByClassName("input__title");
    var input__content = document.getElementsByClassName("input__content");
    var current__title,current__content;
    for( i = 0; i < edit_comment.length; i++ ){
        edit_comment[i].addEventListener('click', function(){
            this.classList.toggle("active");
            editField = this.parentElement.nextElementSibling;
            editField.classList.contains("active_form") ? null : editField.classList.add("active_form");
            current__title = input__title[i-1].value;
            current__content = input__content[i-1].value;
        });
    } 
    for( i = 0; i < comment_back.length; i++ ){
        comment_back[i].addEventListener('click', function(){
            editField = this.parentElement.parentElement;
            editField.classList.contains("active_form") ? editField.classList.toggle("active_form") : null;
            input__title[i-1].value = current__title;
            input__content[i-1].value = current__content;
        });
    }
    for( i = 0; i < comment_update.length; i++ ){
        comment_update[i].addEventListener('click', function(){
            editField = this.parentElement.parentElement;
            editField.classList.contains("active_form") ? editField.classList.toggle("active_form") : null;
            console.log(input__title[i-1].value);
            console.log(input__content[i-1].value);

        });
    }
});