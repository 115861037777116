document.addEventListener('turbolinks:load', function(){
  console.log('Loaded')
  if(document.getElementById('new_informing')) {
    const date = new Date;
    let prepareMintues = (date.getMinutes()<10?'0':'') + date.getMinutes();

    const title = document.getElementById('informing_title');
    const body = document.getElementById('informing_body');
    // const checkbox = document.getElementById('informing_critical');
    document.getElementById('informing-air-alert').addEventListener('click', () => {
      
      const object =  {
          title: '❗️❗️УВАГА Умань повітряна тривога❗️❗️('+date.getHours()+':'+ prepareMintues +')',
          body: 'Зберігайте спокій та знайдіть найближче бомбосховище. Список можна знайти у "Каталозі" ("В єднанні сила")'
        }
      
      title.value = object.title;
      body.value = object.body;
      // checkbox.checked = true;
    })
    document.getElementById('informing-rejected-air-alert').addEventListener('click', () => {
      const object =  {
        title: '🟢 Відбій тривоги 🟢('+date.getHours()+':'+ prepareMintues +')',
        body: 'Не поспішайте покидати укриття, почекайте хвилин 20. І пам’ятайте: кожна повітряна тривога, яка не закінчується авіаударам, - це робота наших ЗСУ!🇺🇦'
      }
      title.value = object.title;
      body.value = object.body;
      // checkbox.checked = true;/
    })
  }
});